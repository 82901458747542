import { SearchDto } from '@/models/Dtos/common/searchDto';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../models/EntityActionButtons';
import { ITableData } from '../models/TableData';
import { IEntityActionBuilder } from './EntityAction';

export abstract class RestoreEntityActionBuilder<
  TableDataType extends ITableData,
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> implements IEntityActionBuilder<TableDataType, EntityType, SearchDtoType>
{
  protected abstract restore: (
    data: TableDataType,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      EntityType,
      SearchDtoType
    >
  ) => Promise<void>;

  build(
    rowData: TableDataType,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      EntityType,
      SearchDtoType
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      text: 'Restore',
      icon: 'refresh',
      disabled: !rowData.isArchived,
      actionHandler: async () => {
        try {
          entityActionButtons.tableSearchService.loaded = false;
          await this.restore(rowData, entityActionButtons);
          await entityActionButtons.store.dispatch('snackBarModule/enqueue', {
            message: `${entityActionButtons.capitalizedTableDataTypeName} restored`,
            icon: 'checkGreen'
          });
          return entityActionButtons.tableSearchService.queryData();
        } catch (err) {
          entityActionButtons.store.dispatch('snackBarModule/enqueue', {
            message: `The ${entityActionButtons.tableDataTypeName} failed to restore.`,
            icon: 'x'
          });
        }
      }
    };
  }
}
