import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { RestoreEntityActionBuilder } from '../../EntityActions/RestoreEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseBoardTableData } from '../BaseBoardTableData';

export class RestoreBoardActionBuilder<
  TableDataType extends IBaseBoardTableData
> extends RestoreEntityActionBuilder<TableDataType, Board, SearchBoardDto> {
  protected restore = async (
    baseBoardTableData: IBaseBoardTableData,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      Board,
      SearchBoardDto
    >
  ): Promise<void> => {
    return await entityActionButtons.store.dispatch(
      'boardModule/restore',
      baseBoardTableData.entityId
    );
  };
}
