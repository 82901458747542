import { ModalMounter } from '@/components/ModalMounter';
import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import LinkElementComponent from '../Elements/LinkElementComponent.vue';
import { EntityActionButtons } from '../models/EntityActionButtons';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import TableSearchService from '../models/TableSearch.service';
import { IBaseBoardTableData } from './BaseBoardTableData';
import { BoardTableData } from './BoardTableData';
import { CloseBoardActionBuilder } from './EntityActions/CloseBoardActionBuilder';
import { DeleteBoardActionBuilder } from './EntityActions/DeleteBoardActionBuilder';
import { ModalEditBoardActionBuilder } from './EntityActions/ModalEditBoardActionBuilder';
import { RestoreBoardActionBuilder } from './EntityActions/RestoreBoardActionBuilder';

export const boardBoardTableConfigBuilder: TableConfigBuilder<
  BoardTableData,
  Board,
  never,
  SearchBoardDto
> = (
  tableSearchService: TableSearchService<
    BoardTableData,
    Board,
    never,
    SearchBoardDto
  >,
  store: Store<RootState>
): ITableConfiguration<BoardTableData, Board, never> => {
  const tableButtons = new EntityActionButtons<
    IBaseBoardTableData,
    Board,
    SearchBoardDto
  >('board', store, tableSearchService, ModalMounter.instance.refs.yesNoModal, {
    editModal: ModalMounter.instance.refs.editBoardModal
  });
  return {
    columnConfigurations: [
      {
        columnTitle: 'Board Title',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: BoardTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.title,
              linkTo: {
                name: Routes.A3_BOARD,
                params: { id: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'title',
        defaultPinned: true
      },
      {
        columnTitle: 'Board Meeting Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.meetingDate
            };
          }
        },
        sortKey: 'meetingDate'
      },
      {
        columnTitle: 'Board Open Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.startDate
            };
          }
        },
        sortKey: 'startDate'
      },
      {
        columnTitle: 'Board Closed Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.endDate
            };
          }
        },
        sortKey: 'endDate'
      },
      {
        columnTitle: 'Number of Applications',
        render: 'numberOfApplications'
        // sortKey: 'numberOfApplications' is a relation. WON'T DO
      },
      tableButtons.buildEntityActionsColumn([
        new CloseBoardActionBuilder(),
        new ModalEditBoardActionBuilder(),
        new DeleteBoardActionBuilder(),
        new RestoreBoardActionBuilder()
      ])
    ]
  };
};
