import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { IBaseBoardTableData } from '../BaseBoardTableData';

export class ModalEditBoardActionBuilder
  implements IEntityActionBuilder<IBaseBoardTableData, Board, SearchBoardDto>
{
  build(
    rowData: IBaseBoardTableData,
    entityActionButtons: EntityActionButtons<
      IBaseBoardTableData,
      Board,
      SearchBoardDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'pencil',
      text: 'Edit',
      disabled: !rowData.isActive || rowData.isArchived,
      actionHandler: async () => {
        if (
          rowData.entityId === null ||
          entityActionButtons.actionOptions?.editModal === undefined
        ) {
          return;
        }
        try {
          const result = await entityActionButtons.actionOptions.editModal.open(
            rowData.entityId
          );
          await this.edit(result, entityActionButtons);
          return entityActionButtons.tableSearchService.queryData();
        } catch (e) {
          if (e) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `${entityActionButtons.capitalizedTableDataTypeName} failed to update, please try again.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }

  protected edit = async (
    board: Board,
    entityActionButtons: EntityActionButtons<
      IBaseBoardTableData,
      Board,
      SearchBoardDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch('boardModule/update', {
      id: board.id,
      payload: board
    });
  };
}
