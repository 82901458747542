import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { IBaseBoardTableData } from '../BaseBoardTableData';

export class DeleteBoardActionBuilder
  implements IEntityActionBuilder<IBaseBoardTableData, Board, SearchBoardDto>
{
  public deleteString: 'delete' | 'archive' = 'archive';

  build(
    rowData: IBaseBoardTableData,
    entityActionButtons: EntityActionButtons<
      IBaseBoardTableData,
      Board,
      SearchBoardDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'trash',
      text: 'Archive',
      iconFill: '#C81111',
      disabled: !rowData.isActive || rowData.isArchived,
      actionHandler: async () => {
        const confirmed = await entityActionButtons.yesNoModal.open({
          title: `${this.capitilizedDeleteString} ${entityActionButtons.capitalizedTableDataTypeName}?`,
          message:
            `Are you sure you want to ${this.deleteString} this ` +
            entityActionButtons.tableDataTypeName +
            '?'
        });
        if (!confirmed) {
          return;
        }
        entityActionButtons.tableSearchService.loaded = false;
        try {
          await this.delete(rowData, entityActionButtons);
          return entityActionButtons.tableSearchService.queryData();
        } catch (err) {
          if (err) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `The ${entityActionButtons.tableDataTypeName} failed to ${this.deleteString}.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }

  get capitilizedDeleteString(): string {
    return this.deleteString.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  }

  protected delete = async (
    BaseBoardTableData: IBaseBoardTableData,
    entityActionButtons: EntityActionButtons<
      IBaseBoardTableData,
      Board,
      SearchBoardDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'boardModule/delete',
      BaseBoardTableData.entityId
    );
  };
}
