import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { IBaseBoardTableData } from '../BaseBoardTableData';

export class CloseBoardActionBuilder
  implements IEntityActionBuilder<IBaseBoardTableData, Board, SearchBoardDto>
{
  build(
    rowData: IBaseBoardTableData,
    entityActionButtons: EntityActionButtons<
      IBaseBoardTableData,
      Board,
      SearchBoardDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'lock',
      iconFill: '#7995fc',
      text: 'Close',
      disabled: !rowData.isActive || rowData.isArchived,
      actionHandler: async () => {
        const confirmed = await entityActionButtons.yesNoModal.open({
          title: `Close ${rowData.title}?`,
          message: `Are you sure you want to close the ${entityActionButtons.tableDataTypeName} ${rowData.title}?`
        });
        if (!confirmed) {
          return;
        }
        try {
          entityActionButtons.tableSearchService.loaded = false;
          await entityActionButtons.store.dispatch('boardModule/update', {
            id: rowData.entityId,
            payload: {
              isActive: false
            },
            path: 'close'
          });
          return entityActionButtons.tableSearchService.queryData();
        } catch (e) {
          if (e) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `${entityActionButtons.capitalizedTableDataTypeName} failed to close, please try again.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }
}
